<template>
  <div class="h-full w-full grid gap-4 participant-tmpl-wrapper">
    <div class="pt-5 md:px-2 lg:px-3 overflow-auto hidden xl:block">
      <sgdf-participant-list :participants="interns" :skeleton="!isSuccess" :currentId="participant?.id" mode="none"
        :sortField="route.query.sortField" :isSortAsc="route.query.isSortAsc"
        @sgdf-participant:navigate="onParticipantClick" @sgdf-participants-sort:change="onSortChange"
        @sgdf-participants-sort-dir:change="onSortDirChange"></sgdf-participant-list>
    </div>
    <div class="pt-2 px-0 overflow-hidden relative main-part">
      <div class="inter-participant-nav flex flex-row-reverse flex-wrap justify-between" style="background: #fff;">
        <div v-if="isSuccessParticipant" class="flex justify-end space-x-2">
          <sgdf-button .disabled="!hasPrevious" @click="onPrev">
            Précédent
          </sgdf-button>
          <sgdf-button .disabled="!hasNext" @click="onNext">
            Suivant
          </sgdf-button>
        </div>
        <sgdf-icon-button name="list" class="block xl:hidden" @click="onBackToList"></sgdf-icon-button>
      </div>
      <div v-if="isSuccessParticipant" ref="simpleHeader" class="hide thesimple">
        <sgdf-participant-view simple="true" :participant="participant" :state="route.query.view"
          @sgdf-participant:show-comment="onShowComment" @sgdf-participant:show-detail="onShowDetail"
          @sgdf-participant:show-validation="onShowValidation" class="simple"><img src="~/assets/images/scoot.png"
            alt="" slot="img-fallback" class="object-fill" />
        </sgdf-participant-view>
      </div>
      <div class="h-full overflow-auto pb-10 l:pb-2" ref="scrollRoot">
        <div class="w-full px-4" v-if="isSuccessParticipant">
          <sgdf-participant-view :participant="participant" :state="route.query.view"
            @sgdf-participant:show-comment="onShowComment" @sgdf-participant:show-detail="onShowDetail"
            @sgdf-participant:show-validation="onShowValidation" ref="bigHeader" class="big-view"><img
              src="~/assets/images/scoot.png" alt="" slot="img-fallback" class="object-fill" />
          </sgdf-participant-view>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import "@sgdf/ui-library/sgdf-participant-list.js";
import { useSortParticipants } from "~/composables/sort-participant";
import { ref, onMounted, onUpdated } from 'vue';

const bigHeader = ref();
const simpleHeader = ref();
const scrollRoot = ref();

const {
  data: participant,
  isSuccess: isSuccessParticipant,
  status,
} = useCurrentStagiaire();

const { data: participants, isSuccess } = useInterns();


const {
  interns,
  route,
} = useSortParticipants({
  participants
})

const meetingId = useMeetingId();

const onSortChange = async (e: CustomEvent<{ sortField: string }>) => {
  await navigateTo(
    RouteUtils.getMeetingParticipant(meetingId.value!, {
      ...route.query,
      sortField: e.detail.sortField,
    })
  );
};

const onSortDirChange = async () => {
  await navigateTo(
    RouteUtils.getMeetingParticipant(meetingId.value!, {
      ...route.query,
      isSortAsc: route.query.isSortAsc === "true" ? "false" : "true",
    })
  );
};


watchEffect(() => {
  if (status.value === "error") {
    navigateTo(RouteUtils.getMeetingParticipants(meetingId.value!));
  }
});



const hasNext = computed(() => {
  return (
    interns.value.findIndex((p) => p.id === participant?.value?.id) <
    interns.value.length - 1
  );
});

const hasPrevious = computed(
  () => interns.value.findIndex((p) => p.id === participant?.value?.id) > 0
);

const onNext = async () => {
  if (!participant.value) return;
  const index = interns.value.findIndex((p) => p.id === participant.value.id);
  if (index < interns.value.length - 1) {
    await navigateTo(
      RouteUtils.getMeetingParticipant(meetingId.value!, {
        ...route.query,
        participantId: interns.value[index + 1].id,
      })
    );
  }
};

const onPrev = async () => {
  if (!participant.value) return;
  const index = interns.value.findIndex((p) => p.id === participant.value.id);
  if (index > 0) {
    await navigateTo(
      RouteUtils.getMeetingParticipant(meetingId.value!, {
        ...route.query,
        participantId: interns.value[index - 1].id,
      })
    );
  }
};

const onParticipantClick = async (
  e: CustomEvent<{
    participantId: string;
    action: "check-in" | "validation" | "interview" | "link";
  }>
) => {
  await navigateTo(
    RouteUtils.getMeetingParticipant(meetingId.value!, {
      ...route.query,
      participantId: e.detail.participantId,
    })
  );
};

const onViewClick = async (e: CustomEvent<string>) => {
  await navigateTo(
    RouteUtils.getMeetingParticipant(meetingId.value!, {
      ...route.query,
      view: e.detail,
    })
  );
};

const onShowComment = async (e: CustomEvent<{ id: string }>) => {
  if (!meetingId.value) return;
  await navigateTo(
    RouteUtils.getMeetingParticipantComment(meetingId.value!, {
      ...route.query,
      participantId: e.detail.id,
    })
  );
};

const onShowDetail = async (e: CustomEvent<{ id: string }>) => {
  if (!meetingId.value) return;
  await navigateTo(
    RouteUtils.getMeetingParticipantDetails(meetingId.value!, {
      ...route.query,
      participantId: e.detail.id,
    })
  );
};

const onShowValidation = async (e: CustomEvent<{ id: string }>) => {
  if (!meetingId.value) return;
  await navigateTo(
    RouteUtils.getMeetingParticipantValidation(meetingId.value!, {
      ...route.query,
      participantId: e.detail.id,
    })
  );
};

const onBackToList = async (e: Event) => {
  if (!meetingId.value) return;
  await navigateTo(
    RouteUtils.getMeetingParticipants(meetingId.value!, {})
  );
}

let observer;

onMounted(() => {
  observer = new IntersectionObserver(
    (entries, obs) => {
      entries.forEach((entry) => {

        if (document.documentElement.clientWidth <= 1200) {
          if (entry.intersectionRect.height > 115) {
            simpleHeader.value.classList.add("hide");
          } else {
            simpleHeader.value?.classList.remove("hide");
          }
        }
      });
    },
    {
      root: scrollRoot.value,
      threshold: [0.25, 0.5, 0.6, 0.7],
    }
  );
});

onUpdated(() => {
  if (bigHeader.value) {
    observer.observe(bigHeader.value);
  }
});
</script>
<style>
.thesimple {
  position: absolute;
  top: 2.6em;
  left: 1rem;
  right: 1rem;
  z-index: 10;
  overflow: hidden;

  &.hide {
    display: none;
  }
}

.inter-participant-nav {
  padding-inline-start: 1em;
  padding-inline-end: 1em;
}

.participant-tmpl-wrapper {
  grid-template-columns: 1fr 3fr;
}

@media (max-width: 1132px) {
  .participant-tmpl-wrapper {
    grid-template-columns: 1fr;
  }

}

@media (min-width: 1280px) {
  .participant-tmpl-wrapper {
    grid-template-columns: 2fr 5fr;
  }
}

@media (max-width: 800px) {
  .thesimple {
    top: 0;
    left: 0;
    right: 0;
    padding: .5rem 1rem 0 1rem;
  }

  .inter-participant-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 30;
    justify-content: start;
    gap: 2em;
    padding-block-start: .3em;
    padding-block-end: .5em;
  }
}
</style>
